import './App.css';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/NavBar';
import RouteHandler from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './contexts/AppContext';
import { ContactModal } from './components/modals/ContactModal';
import ModalContainer from './routes/ModalContainer';


function App() {

  return (
    <BrowserRouter>
      <Analytics />
      <SpeedInsights />
      <AppProvider>
        <ModalContainer />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
