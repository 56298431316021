import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaCalendarCheck } from "react-icons/fa";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./BookNowModal.css";
import { Link } from "react-router-dom";

const ExampleWrapper = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="wrapper">
            <button
                onClick={() => setIsOpen(true)}
                className="open-button"
            >
                Open Modal
            </button>
            <BookNowModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export const BookNowModal = ({ isOpen, setIsOpen }) => {

    return (
        <AnimatePresence>
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="dark"
            />
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="modal-container"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="modal-content"
                    >
                        <GiTakeMyMoney className="money-icon" />
                        <div className="modal-content-inner">
                            <div className="i-icon-middle">
                                <FaCalendarCheck />
                            </div>
                            <h3 className="big-text">
                                Book Now!
                            </h3>
                            <p className="text">
                                Find CHEAPER deals with some of our cars with private hire or check out our Turo Account to book majority of our fleet
                            </p>
                            <div className="button-group">
                                <Link
                                    to='https://turo.com/gb/en/drivers/32411254'
                                    target="_blank"
                                    className="book-now-button-decline"
                                >
                                    Book on Turo
                                </Link>
                                <Link
                                    onClick={() => setIsOpen(false)}
                                    to={'/private_rental'}
                                    className="book-now-button-accept"
                                >
                                    Book Privately
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ExampleWrapper;
