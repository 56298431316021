import React from 'react'
import './FrequentlyAsked.css'
import QandA from '../smallComponents/QandA/QandA'

const FrequentlyAsked = () => {

    const data = [
        {
            "question": "How does renting a car on Turo work?",
            "answer": "Renting a car on Turo is easy! Simply browse through our selection of available cars, choose the one you like, and book it for the dates you need after making an account. You can communicate with us easily through the Turo platform to arrange pick-up and drop-off details."
        },
        {
            "question": "What are the requirements for renting a car on either Turo or Privately?",
            "answer": "To rent a car on both, you must be at least 18 years old (however most of our cars require you to be 25+), and have a valid driver's license. Renting privately will require some extra documents to be signed and a deposit to be paid. Please check each listing in case additional requirements are needed"
        },
        {
            "question": "How much does it cost to rent a car with Royz?",
            "answer": "The cost of renting a car on Turo varies depending on factors such as the make and model of the car, the rental duration, and any additional services or insurance options selected. You can find the exact pricing listed on each car's profile page for Turo whereas privately we will most likely charge same as Turo day rate prices, but as you will not need to pay extra for the mandatory insurance that you will have to on Turo, it works out much cheaper for you over Turo prices"
        },
        {
            "question": "Is insurance included when renting a car on Turo?",
            "answer": "Yes, Turo provides insurance coverage for both hosts and guests at an extra charge. All rentals include liability insurance coverage, and guests have the option to purchase additional insurance for added peace of mind during their trip. For private rentals there is one type of self-hire insurance."
        },
        {
            "question": "What if I need to cancel my reservation?",
            "answer": "If you need to cancel your reservation, you can do so through the Turo website or app. The cancellation policy varies depending on the host and the specific listing, so be sure to review the cancellation policy before booking. Private booking cancellations require 3 days notice at least."
        },
        {
            "question": "Can I modify my reservation after booking?",
            "answer": "Yes, you can modify your reservation through the Turo platform or contacting the team for Private Hires. This includes changing the rental dates, adding or removing additional services, or updating your insurance options. Keep in mind that any changes may be subject to approval by the host (but most of the time we do accommodate)"
        },
        {
            "question": "How long can I rent for?",
            "answer": "As long as it is not booked by another guest then the car can be booked for weeks, even months if necessary. Do drop us an email or call for longer rentals and we maybe able to sort out private hire to benefit both parties"
        },
        {
            "question": "What happens if the car is damaged while renting?",
            "answer": "We understand most people renting are a bit rusty behind the wheel so we expect a few issues here and there. As long as both parties are honest and we come to a agreement over fees for repair (We provide proof of bill), most issues are sorted privately between parties or escalated via Turo if nothing can be settled. Private hire requires a deposit in most cases for this reason."
        },
        {
            "question": "When will I get back my deposit?",
            "answer": "The deposit is held for both damage and tickets/tolls received while renting the vehicle. Therefore we can hold the deposit for around a month to make sure the any possible post trip expenses are also covered"
        }
    ]




    return (
        <section className='faq-container' id='FAQ'>

            <div className='text-box'>
                <h3 className='small-title-black' style={{ alignSelf: 'center' }}>FAQ</h3>
                <h1>Frequently Asked Questions</h1>
                <p>Answers to common questions that you may have about our service</p>
            </div>
            <div className='qa-container'>
                {data.map((item, index) =>
                    <QandA item={item} index={index} key={item.question} />
                )}
            </div>


        </section>
    )
}

export default FrequentlyAsked