import React, { useState } from 'react'
import './QandA.css'
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";

const QandA = ({ item, index }) => {
    const [showAnswer, setShowAnswer] = useState(index === 0 ? true : false)


    const handlePress = () => {
        setShowAnswer((prev) => !prev)
    }


    return (
        <div className='container' onClick={handlePress}
        >
            <div className='question-container' style={{
                backgroundColor: showAnswer && '#8105d3',
                color: showAnswer && 'white',
            }}>
                <div className='question-text'

                >{item.question}

                </div>
                {showAnswer ?
                    <IoIosArrowDropup className='icon' style={{ color: 'white', }} />
                    :
                    <IoIosArrowDropdown className='icon' />}
            </div>
            {showAnswer && <div className='answer'>{item.answer}</div>}
        </div>
    )
}

export default QandA