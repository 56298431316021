import React from 'react'
import './HowItWorks.css'
import { IoCarSport } from "react-icons/io5";
import { SiTransportforlondon } from "react-icons/si";
// import { NavLink } from 'react-router-dom';
// import { Slide } from 'react-awesome-reveal';
import { BsCalendarDateFill } from "react-icons/bs";
import { BiSolidHappyBeaming } from "react-icons/bi";
import { HashLink } from 'react-router-hash-link';
import { SiGoogleforms } from "react-icons/si";
import { TfiWrite } from "react-icons/tfi";

const HowItWorks = () => {

    const turo_hiw_data = [
        {
            title: 'Pick Your Dates',
            number: null,
            description: 'Choose from the available dates to rent the car. Make it your own for days, weeks even months',
            icon: <BsCalendarDateFill size={'40px'} />
        },
        {
            title: 'Select a Car',
            number: '10+',
            description: 'We offer a big range of vehicles for all your driving needs. Give our selection and browse for yourself and see!',
            icon: <IoCarSport size={'40px'} />
        },

        {
            title: 'Drive Away!',
            number: <SiTransportforlondon size={'40px'} />,
            description: 'Collect your car at the designated central London locations with minimum fuss and then enjoy your new high-end ride!',
            icon: <BiSolidHappyBeaming size={'48px'} />
        }
    ];

    const private_hiw_data = [
        {
            title: 'Eligibility Form',
            number: null,
            description: 'Due to our self-hire policy we require you to tick a few boxes before we can offer Private Rental',
            icon: <SiGoogleforms size={'35px'} />
        },
        {
            title: 'Select Cars',
            number: '10+',
            description: "Let us know which of our select fleet you'd like to rent and we'll send quotes for each with your given dates",
            icon: <div className='all-cars-png'>
                <img src={require('../../assets/small_assets/all-cars.png')} alt="all cars" style={{ width: '70%' }} />
            </div>
        },
        {
            title: 'Sign Contract',
            number: '10+',
            description: "E-signature document will be sent to email, payment and deposit will be sorted at this stage",
            icon: <TfiWrite size={'35px'} />
        },

        {
            title: 'Drive Away!',
            number: <SiTransportforlondon size={'40px'} />,
            description: 'Collect your car at the designated central London locations with minimum fuss and then enjoy your new high-end ride!',
            icon: <BiSolidHappyBeaming size={'48px'} />
        }
    ];


    return (
        <section className='features-container' id='how-it-works'>
            <h3 className='small-title-black'>How it Works</h3>
            <h1 className='features-heading'>For Turo Hire</h1>
            <div className='list-container'>
                {turo_hiw_data.map((item, index) =>
                    <HashLink className='individual-feature-container' key={"how" + index} to="/#our-fleet" smooth>
                        <div className='individual-feature-top-bar'>
                            {/* <div className='icon'>{item.icon}</div> */}
                            <div className='feature-title'> {item.title}</div>
                            <div className='feature-circle'
                            // style={{
                            //     backgroundColor: !item.number && 'transparent',
                            //     boxShadow: !item.number && 'none'
                            // }}
                            >
                                {item.icon}
                            </div>
                        </div>
                        <div className='feature-description'>{item.description}</div>
                    </HashLink>
                )}
            </div>
            <h1 className='features-heading'>For Private Hire</h1>
            <div className='list-container'>
                {private_hiw_data.map((item, index) =>
                    <HashLink className='individual-feature-container-alt' key={"how" + index} to="/#our-fleet" smooth>
                        <div className='individual-feature-top-bar'>
                            {/* <div className='icon'>{item.icon}</div> */}
                            <div className='feature-title'> {item.title}</div>
                            <div className='feature-circle-alt'
                            // style={{
                            //     backgroundColor: !item.number && 'transparent',
                            //     boxShadow: !item.number && 'none'
                            // }}
                            >
                                {item.icon}</div>
                        </div>
                        <div className='feature-description' >{item.description}</div>
                    </HashLink>
                )}
            </div>

            {/* <div className="custom-shape-divider-bottom-1709119856">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                </svg>
            </div> */}
        </section>
    )
}

export default HowItWorks