import React, { createContext, useEffect, useState } from 'react';
import { fetchTSVData } from '../lib/smallFunctions';
import { useNavigate } from 'react-router-dom';




export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [showPopup, setShowPopup] = useState(null);
    const [sheetsCarData, setSheetsCarData] = useState([])
    const [loadingSheetsData, setLoadingSheetsData] = useState(true)
    const [showCarDetails, setShowCarDetails] = useState(false)
    const [showBookNow, setShowBookNow] = useState(false)
    const [chosenCarIndex, setChosenCarIndex] = useState(null)
    const [categoryFilter, setCategoryFilter] = useState('all');
    const navigate = useNavigate();

    // const handleClickCar = (index) => {
    //     setChosenCarIndex(index)
    //     setShowCarDetails(true)
    //     // if (!index) setChosenCarIndex(false)
    // }

    const formatCarNameForURL = (name) => {
        return name.toLowerCase().replace(/ /g, '_');
    };

    const handleClickCar = (index, item) => {
        // setChosenCarIndex(index);
        const formattedName = formatCarNameForURL(item.make + "_" + item.name + "_" + item.year_of_manufacture + "_" + item.color);
        navigate(`/our_fleet/${item.id}/${formattedName}`);
    };

    useEffect(() => {
        setLoadingSheetsData(true)
        fetchTSVData(setSheetsCarData)
        setLoadingSheetsData(false)
    }, [])


    return (
        <AppContext.Provider
            value={{
                showPopup, setShowPopup,
                showCarDetails, setShowCarDetails,
                chosenCarIndex, setChosenCarIndex,
                handleClickCar,
                sheetsCarData, setSheetsCarData,
                loadingSheetsData, setLoadingSheetsData,
                categoryFilter, setCategoryFilter,
                showBookNow, setShowBookNow
            }}>
            {children}
        </AppContext.Provider>
    );
};