export const handleIconSource = (iconName) => {
    try {
        console.log(`../assets/car_images/logos/${iconName}.png`)
        return require(`../assets/car_images/logos/${iconName}.png`)
    }
    catch (e) {
        return null
    }
}

export const fetchTSVData = (setTsvData) => {
    const tsvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vStkrC9a9tWL-eKqMJKGcbvxAopi657lb2hm38h80bmVwT-L8SzGu-Yh_lGm32185_VxBhnS36mHlyz/pub?output=tsv'; // Replace with your Google Sheets TSV file URL

    fetch(tsvUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
                // TODO use saved data instead of network data if error
            }
            return response.text();
        })
        .then(tsvText => {
            const parsedTsvData = parseTSV(tsvText);        // Parse the TSV data into an array of objects
            setTsvData(parsedTsvData);        // Set the fetched data in the component's state
            // console.log({ parsedTsvData });        // Now you can work with 'tsvData' in your component's state.
        })
        .catch(error => {
            console.error('Error fetching TSV data:', error);
        });
};

function parseTSV(tsvText) {
    const rows = tsvText.split(/\r?\n/);        // Use a regular expression to split the TSV text into rows while handling '\r'
    const headers = rows[0].split('\t');        // Extract headers (assumes the first row is the header row) by splitting on tabs
    const data = [];        // Initialize an array to store the parsed data
    for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split('\t');          // Use the regular expression to split the row while handling '\r'
        const rowObject = {};
        for (let j = 0; j < headers.length; j++) {
            let value = rowData[j];
            // Convert "TRUE" to true
            if (value.toUpperCase() === "TRUE") {
                rowObject[headers[j]] = true;
            }
            // Convert "FALSE" to false
            else if (value.toUpperCase() === "FALSE") {
                rowObject[headers[j]] = false;
            }
            // Convert "NULL" to null
            else if (value.toUpperCase() === "NULL") {
                rowObject[headers[j]] = null;
            }
            // Convert string array to actual array
            else if (value.startsWith("['") && value.endsWith("']")) {
                // Replace single quotes with double quotes and remove spaces after commas
                value = value.replace(/'/g, '"').replace(/,\s*/g, ',');
                try {
                    rowObject[headers[j]] = JSON.parse(value);
                } catch (error) {
                    console.error("Error parsing array:", error);
                    rowObject[headers[j]] = value; // Fallback to original value in case of error
                }
            }
            // Otherwise, keep the value as is
            else {
                rowObject[headers[j]] = value;
            }
        }
        data.push(rowObject);
    }
    return data;
}




