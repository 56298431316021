export const our_fleet = [

    {
        id: 13,
        "registration_number": "FM18LYF",
        "make": "BMW",
        "name": "3 Series",
        "model": "320I M SPORT AUTO",
        "year_of_manufacture": "2018",
        "date_of_purchase": "09 June 2024",
        "active": true,
        "color": "blue",
        color_hex: '#025ce7',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/bmw_3_series_blue_1.png'),
            require('../assets/our_fleet/bmw_3_series_blue_2.png'),
            require('../assets/our_fleet/bmw_3_series_blue_3.png'),
            require('../assets/our_fleet/bmw_3_series_blue_4.png'),
            require('../assets/our_fleet/bmw_3_series_blue_5.png'),
            require('../assets/our_fleet/bmw_3_series_blue_6.png'),
            require('../assets/our_fleet/bmw_3_series_blue_7.png')
        ],
        "fuel_type": "Petrol",
        "parking_zone": "CA-G",
        "number_of_seats": "5",
        "price": "£70",
        "rating": "4.95",
        "parked_location": "Augustus Street, London, NW1 3TJ",
        "geo_location_lat": "51.53015208",
        "geo_location_lng": "-0.1417315809",
        "nearby_stations": ['Warren Street', 'Euston', 'Kings Cross'],
        "number_of_rides": "24",
        "number_of_reviews": "21",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/bmw/3-series/2795361",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": false,
        "bluetooth": true,
        "phone_mount": true,
        "usb_charger_cable": true,
        "backup_camera": false,
        "blind_spot_sensor": true,
        "bird_eye_camera": false,
        "heated_seats": false,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "medium",
    },

    {
        id: 10,
        "registration_number": "LG70XPJ",
        "make": "Audi",
        "name": "Q8 S LINE",
        "model": "ED 1 50 TDI QUAT A",
        "year_of_manufacture": "2020",
        "date_of_purchase": null,
        "active": true,
        "color": "black",
        color_hex: '#000',
        "turo_account": "Rahi",
        "images": [
            require('../assets/our_fleet/audi_q8_s_line_1.png'),
            require('../assets/our_fleet/audi_q8_s_line_2.png'),
            require('../assets/our_fleet/audi_q8_s_line_3.png'),
            require('../assets/our_fleet/audi_q8_s_line_4.png'),
            require('../assets/our_fleet/audi_q8_s_line_5.jpg'),
            require('../assets/our_fleet/audi_q8_s_line_6.jpg'),
            require('../assets/our_fleet/audi_q8_s_line_7.jpg')
        ],
        "fuel_type": "Diesel",
        "parking_zone": null,
        "number_of_seats": "5",
        "price": "£170",
        "rating": "5.00",
        "parked_location": "St Matthew's Rd, London SW2 1ND",
        "geo_location_lat": 51.458621,
        "geo_location_lng": -0.115844,
        "number_of_rides": "11",
        "number_of_reviews": "11",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/audi/q8/2528655",
        "private_hire_available": true,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": true,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": true,
        "bird_eye_camera": true,
        "heated_seats": true,
        "panoramic_roof": true,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "large",
        "nearby_stations": ['Russel Square', 'Kings Cross']
    },
    {
        id: 14,
        "registration_number": "AP66OJT",
        "make": "Land Rover",
        "name": "DISCOVERY SPORT",
        "model": "LUXURY HSE TD4 A",
        "year_of_manufacture": "2016",
        "date_of_purchase": "12 June 2024",
        "active": true,
        "color": "brown",
        color_hex: '#90887d',
        "turo_account": "Rahi",
        "images":
            [
                require('../assets/our_fleet/land rover/brown_discovery_sport_1.jpg'),
                require('../assets/our_fleet/land rover/brown_discovery_sport_2.jpg'),
                require('../assets/our_fleet/land rover/brown_discovery_sport_3.jpg'),
                require('../assets/our_fleet/land rover/brown_discovery_sport_4.jpg'),
                require('../assets/our_fleet/land rover/brown_discovery_sport_5.jpg'),
                require('../assets/our_fleet/land rover/brown_discovery_sport_6.jpg'),
                require('../assets/our_fleet/land rover/brown_discovery_sport_7.jpg'),
            ],
        "fuel_type": "Diesel",
        "parking_zone": "CA-G",
        "number_of_seats": "7",
        "price": "£100",
        "rating": "4.9",
        "parked_location": "Augustus Street, London, NW1 3TJ",
        "geo_location_lat": "51.53125208",
        "geo_location_lng": "-0.1419315809",
        "nearby_stations": ['Warren Street', 'Euston', 'Kings Cross'],
        "number_of_rides": "46",
        "number_of_reviews": "37",
        "turo_link": "https://turo.com/gb/en/suv-rental/united-kingdom/undefined/land-rover/discovery-sport/2722308",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": false,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": true,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": true,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "white",
        "boot_space": "large",

    },
    {
        id: 2,
        "registration_number": "LD69BUH",
        "make": "BMW",
        "name": "2 Series",
        "model": "218I M SPORT AUTO",
        "year_of_manufacture": "2019",
        "date_of_purchase": "26 June 2024",
        "active": true,
        "color": "red",
        color_hex: '#a8202c',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/bmw_2_series_convertible_1.png'),
            require('../assets/our_fleet/bmw_2_series_convertible_2.png'),
            require('../assets/our_fleet/bmw_2_series_convertible_3.png'),
            require('../assets/our_fleet/bmw_2_series_convertible_4.png'),
            require('../assets/our_fleet/bmw_2_series_convertible_5.jpg')
        ],
        "fuel_type": "Petrol",
        "parking_zone": "CA-E",
        "number_of_seats": "4",
        "price": "£80",
        "rating": "5.00",
        "parked_location": "Huntley St, London WC1E 6DD",
        "geo_location_lat": "51.52171408",
        "geo_location_lng": "-0.133290927",
        "number_of_rides": "50",
        "number_of_reviews": "43",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/undefined/bmw/2-series/2608952",
        "private_hire_available": true,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": false,
        "blind_spot_sensor": true,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": false,
        "convertible": true,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "medium",
        "nearby_stations": ['Warren Street', 'Euston Square', 'Goodge Street']
    },
    {
        id: 9,
        "registration_number": "MH18XCK",
        "make": "Land Rover",
        "name": "RANGE ROVER",
        "model": "A-BIO TDV6 A",
        "year_of_manufacture": "2018",
        "date_of_purchase": null,
        "active": false,
        "color": "black",
        color_hex: '#000',
        "turo_account": "Rahi",
        "images": [
            require('../assets/our_fleet/range_rover_red_interior_2018__1.jpg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__2.jpg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__3.jpg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__4.jpg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__5.jpg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__6.jpeg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__7.jpeg'),
            require('../assets/our_fleet/range_rover_red_interior_2018__8.jpeg')
        ],
        "fuel_type": "Diesel",
        "parking_zone": "CA-D/E",
        "number_of_seats": "5",
        "price": "£160",
        "rating": "5.00",
        "parked_location": "London, WC1H8HX",
        "geo_location_lat": "51.52662017",
        "geo_location_lng": "-0.1215208869",
        "number_of_rides": "10",
        "number_of_reviews": "10",
        // "turo_link": "https://turo.com/gb/en/suv-rental/united-kingdom/london/land-rover/range-rover/2162601",
        "private_hire_available": true,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": true,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": true,
        "bird_eye_camera": true,
        "heated_seats": true,
        "panoramic_roof": true,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "red",
        "boot_space": "large",
        "nearby_stations": ['Russel Square', 'Kings Cross']
    },
    {
        id: 11,
        "registration_number": "AY18VCO",
        "make": "Land Rover",
        "name": "RANGE ROVER SPORT",
        "model": "A-BIO DYN SDV6 A",
        "year_of_manufacture": "2018",
        "date_of_purchase": null,
        "active": false,
        "color": "black",
        color_hex: '#000',
        "turo_account": "Rahi",
        "images": [
            require('../assets/our_fleet/range_rover_white_interior_2018__1.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__2.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__3.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__4.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__5.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__6.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__7.jpg'),
            require('../assets/our_fleet/range_rover_white_interior_2018__8.jpg')
        ],
        "fuel_type": "Diesel",
        "parking_zone": "CA-C",
        "number_of_seats": "5",
        "price": "£160",
        "rating": "5.00",
        "parked_location": "Huntley St, London WC1E 6DD",
        "geo_location_lat": null,
        "geo_location_lng": null,
        "number_of_rides": "20",
        "number_of_reviews": "16",
        "turo_link": null,
        "private_hire_available": true,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": true,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": true,
        "bird_eye_camera": true,
        "heated_seats": true,
        "panoramic_roof": true,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "white",
        "boot_space": "large",
        "nearby_stations": ['Warren Street', 'Euston Square', 'Goodge Street']
    },
    {
        id: 15,
        "registration_number": "AK16KHC",
        "make": "Jaguar",
        "name": "XE",
        "model": "PRESTIGE D AUTO",
        "year_of_manufacture": "2016",
        "date_of_purchase": "17 August 2024",
        "active": true,
        "color": "dark blue",
        color_hex: '#012256',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/jaguar/blue_xe_2.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_1.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_3.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_4.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_5.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_6.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_7.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_8.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_9.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_10.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_11.jpg'),
            require('../assets/our_fleet/jaguar/blue_xe_12.jpg'),
        ],
        "fuel_type": "Diesel",
        "parking_zone": "CA-G",
        "number_of_seats": "5",
        "price": "£70",
        "rating": "4.95",
        "parked_location": "Ossulston Street, London, NW1 1EL",
        "geo_location_lat": "51.53151733",
        "geo_location_lng": "-0.1304244537",
        "number_of_rides": "25",
        "number_of_reviews": "21",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/jaguar/xe/2824741",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": true,
        "usb_charger_cable": true,
        "backup_camera": true,
        "blind_spot_sensor": true,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "white",
        "boot_space": "medium",
        "nearby_stations": ['Kings Cross', 'Euston']
    },
    // {
    // id: 5,
    //     "registration_number": "YP18NVL",
    //     "make": "BMW",
    //     "name": "5 Series",
    //     "model": "530E M SPORT AUTO",
    //     "year_of_manufacture": "2018",
    //     "date_of_purchase": null,
    //     "active": true,
    //     "color": "grey",
    //     color_hex: '#3f4448',
    //     "turo_account": "Shuhebur",
    //     "images": [
    //         require('../assets/our_fleet/bmw_5_series_2018_1.png'),
    //         require('../assets/our_fleet/bmw_5_series_2018_2.jpg'),
    //         require('../assets/our_fleet/bmw_5_series_2018_3.jpg'),
    //         require('../assets/our_fleet/bmw_5_series_2018_4.jpg')
    //     ],
    //     "fuel_type": "Hybrid Electric",
    //     "parking_zone": "CA-E",
    //     "number_of_seats": "5",
    //     "price": "£80",
    //     "rating": "4.97",
    //     "parked_location": "Huntley St, London WC1E 6DD",
    //     "geo_location_lat": "51.522422",
    //     "geo_location_lng": "-0.133782",
    //     "number_of_rides": "37",
    //     "number_of_reviews": "30",
    //     "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/bmw/5-series/2532188",
    //     "private_hire_available": false,
    //     "automatic": true,
    //     "android_auto": false,
    //     "apple_carplay": false,
    //     "aux_input": true,
    //     "bluetooth": true,
    //     "phone_mount": false,
    //     "usb_charger_cable": false,
    //     "backup_camera": false,
    //     "blind_spot_sensor": false,
    //     "bird_eye_camera": false,
    //     "heated_seats": false,
    //     "panoramic_roof": false,
    //     "convertible": false,
    //     "congestion_free": true,
    //     "navigation": true,
    //     "interior_color": "black",
    //     "boot_space": "medium",
    //     "nearby_stations": ['Warren Street', 'Euston Square', 'Goodge Street']
    // },
    {
        id: 16,
        "registration_number": "RX16EYY",
        "make": "Land Rover",
        "name": "RANGE ROVER EVOQUE",
        "model": "SE TD4 AUTO",
        "year_of_manufacture": "2016",
        "date_of_purchase": "08 September 2024",
        "active": true,
        "color": "brown",
        color_hex: '#90887d',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/land rover/brown_evoque_1.jpg'),
            require('../assets/our_fleet/land rover/brown_evoque_2.jpg'),
            require('../assets/our_fleet/land rover/brown_evoque_3.jpg'),
            require('../assets/our_fleet/land rover/brown_evoque_4.jpg'),
            require('../assets/our_fleet/land rover/brown_evoque_5.jpg'),
            require('../assets/our_fleet/land rover/brown_evoque_6.jpg'),
        ],
        "fuel_type": "Diesel",
        "parking_zone": "CA-G",
        "number_of_seats": "5",
        "price": "£65",
        "rating": "5.00",
        "parked_location": "Ossulston Street, London, NW1 1EL",
        "geo_location_lat": 51.53151933,
        "geo_location_lng": -0.1304244537,
        "number_of_rides": "12",
        "number_of_reviews": "11",
        "turo_link": "https://turo.com/gb/en/suv-rental/united-kingdom/london/land-rover/range-rover-evoque/2857872",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": true,
        "usb_charger_cable": true,
        "backup_camera": false,
        "blind_spot_sensor": true,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "white",
        "boot_space": "large",
        "nearby_stations": ['Kings Cross', 'Euston']
    },
    {
        id: 12,
        "registration_number": "X44EJS",
        "make": "BMW",
        "name": "X5",
        "model": "X-DRIVE 30D M SPORT AUTO",
        "year_of_manufacture": "2014",
        "date_of_purchase": "04 June 2024",
        "active": true,
        "color": "grey",
        color_hex: '#77899f',
        "turo_account": "Rahi",
        "images": [
            require('../assets/our_fleet/bmw_x5_grey_1.png'),
            require('../assets/our_fleet/bmw_x5_grey_2.png'),
            require('../assets/our_fleet/bmw_x5_grey_3.png'),
            require('../assets/our_fleet/bmw_x5_grey_4.png'),
            require('../assets/our_fleet/bmw_x5_grey_5.png'),
            require('../assets/our_fleet/bmw_x5_grey_6.png'),
            require('../assets/our_fleet/bmw_x5_grey_7.png')
        ],
        "fuel_type": "Diesel",
        "parking_zone": "GGS",
        "number_of_seats": "5",
        "price": "£85",
        "rating": "5",
        "parked_location": "London, E11 4BE",
        "geo_location_lat": "51.565004",
        "geo_location_lng": "0.001367",
        "number_of_rides": "8",
        "number_of_reviews": "5",
        "turo_link": "https://turo.com/gb/en/suv-rental/united-kingdom/london/bmw/x5/2713677",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": false,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "large",
        "nearby_stations": ['Leytonstone']
    },
    {
        id: 6,
        "registration_number": "AO71YXN",
        "make": "Mercedes-Benz",
        "name": "GLB 200",
        "model": "AMG LINE AUTO",
        "year_of_manufacture": "2021",
        "date_of_purchase": null,
        "active": true,
        "color": "black",
        color_hex: '#01060a',
        "turo_account": "Rahi",
        "images": [
            require('../assets/our_fleet/mercedes_glb_1.png'),
            require('../assets/our_fleet/mercedes_glb_2.png'),
            require('../assets/our_fleet/mercedes_glb_3.png'),
            require('../assets/our_fleet/mercedes_glb_4.png'),
            require('../assets/our_fleet/mercedes_glb_5.jpg'),
            require('../assets/our_fleet/mercedes_glb_6.jpg')
        ],
        "fuel_type": "Petrol",
        "parking_zone": "CA-D/E",
        "number_of_seats": "7",
        "price": "£110",
        "rating": "4.97",
        "parked_location": "Gordon St, London, WC1H 0AH",
        "geo_location_lat": "51.52359",
        "geo_location_lng": "-0.13099",
        "number_of_rides": "49",
        "number_of_reviews": "42",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/mercedes-benz/glb-class/2363589",
        "private_hire_available": true,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": false,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "large",
        "nearby_stations": ["Euston", "Euston Square"]
    },
    {
        id: 8,
        "registration_number": "GK16VTW",
        "make": "Land Rover",
        "name": "DISCOVERY SPORT",
        "model": "HSE",
        "year_of_manufacture": "2016",
        "date_of_purchase": null,
        "active": true,
        "color": "white",
        color_hex: '#e5e9f3',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/range_rover_discovery_2016_1.png'),
            require('../assets/our_fleet/range_rover_discovery_2016_2.png'),
            require('../assets/our_fleet/range_rover_discovery_2016_3.png'),
            require('../assets/our_fleet/range_rover_discovery_2016_4.jpg'),
            require('../assets/our_fleet/range_rover_discovery_2016_5.jpg'),
            require('../assets/our_fleet/range_rover_discovery_2016_6.jpg'),
            require('../assets/our_fleet/range_rover_discovery_2016_7.jpg'),
            require('../assets/our_fleet/range_rover_discovery_2016_8.jpg')
        ],
        "fuel_type": "Diesel",
        "parking_zone": "GGS",
        "number_of_seats": "7",
        "price": "£110",
        "rating": "5.00",
        "parked_location": "London, E11 4BE",
        "geo_location_lat": "51.565004",
        "geo_location_lng": "0.001367",
        "number_of_rides": "23",
        "number_of_reviews": "17",
        "turo_link": "https://turo.com/gb/en/suv-rental/united-kingdom/undefined/land-rover/discovery-sport/2671148",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": true,
        "blind_spot_sensor": false,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": true,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "large",
        "nearby_stations": ['Leytonstone']
    },
    {
        id: 1,
        "registration_number": "AU69GHF",
        "make": "BMW",
        "name": "2 Series",
        "model": "218I M SPORT AUTO",
        "year_of_manufacture": "2019",
        "date_of_purchase": "16 March 2024",
        "active": true,
        "color": "black",
        color_hex: '#090d10',
        "turo_account": "Shuhebur",
        images: [
            require('../assets/our_fleet/bmw/black_2_series_7_seater_1.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_2.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_3.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_4.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_5.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_6.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_7.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_8.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_9.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_10.JPG'),
            require('../assets/our_fleet/bmw/black_2_series_7_seater_11.JPG'),
        ],
        "fuel_type": "Petrol",
        "parking_zone": "CA-G",
        "number_of_seats": "7",
        "price": "£99",
        "rating": "4.90",
        "parked_location": "Ossulston Street, London, NW1 1EL",
        "geo_location_lat": "51.53212598",
        "geo_location_lng": "-0.1310360983",
        "number_of_rides": "44",
        "number_of_reviews": "42",
        "turo_link": "https://turo.com/gb/en/suv-rental/united-kingdom/undefined/bmw/2-series/2593770",
        "private_hire_available": true,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": true,
        "usb_charger_cable": true,
        "backup_camera": false,
        "blind_spot_sensor": true,
        "bird_eye_camera": false,
        "heated_seats": true,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "black",
        "boot_space": "large",
        "nearby_stations": ['Kings Cross', 'Euston']
    },
    // {
    //     "registration_number": "BJ14PTX",
    //     "make": "Nissan",
    //     "name": "Note",
    //     "model": "ACENTA PREMIUM",
    //     "year_of_manufacture": "2014",
    //     "date_of_purchase": null,
    //     "active": true,
    //     "color": "light blue",
    //     color_hex: '#4ca9f8',
    //     "turo_account": "Rahi",
    //     "images": [
    //         require('../assets/our_fleet/nissan_note_1.jpg'),
    //         require('../assets/our_fleet/nissan_note_2.jpg'),
    //         require('../assets/our_fleet/nissan_note_3.jpg'),
    //         require('../assets/our_fleet/nissan_note_4.jpg'),
    //         require('../assets/our_fleet/nissan_note_5.jpg'),
    //         require('../assets/our_fleet/nissan_note_6.jpg')
    //     ],
    //     "fuel_type": "Petrol",
    //     "parking_zone": "CA-G",
    //     "number_of_seats": "5",
    //     "price": "£45",
    //     "rating": "4.8",
    //     "parked_location": "Stanhope Street, London, NW1 3RJ",
    //     "geo_location_lat": "51.52714003",
    //     "geo_location_lng": "-0.1367337239",
    //     "number_of_rides": "15",
    //     "number_of_reviews": "9",
    //     "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/nissan/note/2713629",
    //     "private_hire_available": false,
    //     "automatic": true,
    //     "android_auto": false,
    //     "apple_carplay": false,
    //     "aux_input": false,
    //     "bluetooth": true,
    //     "phone_mount": false,
    //     "usb_charger_cable": true,
    //     "backup_camera": false,
    //     "blind_spot_sensor": true,
    //     "bird_eye_camera": false,
    //     "heated_seats": false,
    //     "panoramic_roof": false,
    //     "convertible": false,
    //     "congestion_free": false,
    //     "navigation": false,
    //     "interior_color": "black",
    //     "boot_space": "small",
    //     "nearby_stations": ['Warren Street', 'Euston', 'Kings Cross']
    // },
    {
        id: 7,
        "registration_number": "DN16HDD",
        "make": "Vauxhall",
        "name": "ASTRA",
        "model": "SRI NAV TURBO S/S AUTO",
        "year_of_manufacture": "2016",
        "date_of_purchase": null,
        "active": true,
        "color": "red",
        color_hex: '#e50108',
        "turo_account": "Rahi",
        "images": [
            require('../assets/our_fleet/vauxhall/astra_red_2016_1.jpg'),
            require('../assets/our_fleet/vauxhall/astra_red_2016_2.jpg'),
            require('../assets/our_fleet/vauxhall/astra_red_2016_3.jpg'),
            require('../assets/our_fleet/vauxhall/astra_red_2016_4.jpg'),
            require('../assets/our_fleet/vauxhall/astra_red_2016_5.jpg'),
            require('../assets/our_fleet/vauxhall/astra_red_2016_6.jpg'),
        ],
        "fuel_type": "Petrol",
        "parking_zone": "CA-G",
        "number_of_seats": "5",
        "price": "£50",
        "rating": "5.00",
        "parked_location": "Stanhope Street, London, NW1 3RJ",
        "geo_location_lat": "51.52914003",
        "geo_location_lng": "-0.1407337239",
        "number_of_rides": "96",
        "number_of_reviews": "70",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/london/vauxhall/astra/2080977",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": false,
        "blind_spot_sensor": false,
        "bird_eye_camera": false,
        "heated_seats": false,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": false,
        "navigation": true,
        "interior_color": "grey",
        "boot_space": "medium",
        "nearby_stations": ['Warren Street', 'Euston', 'Kings Cross']
    },
    {
        id: 3,
        "registration_number": "MM67DTN",
        "make": "BMW",
        "name": "1 Series",
        "model": "118D SE AUTO",
        "year_of_manufacture": "2017",
        "date_of_purchase": null,
        "active": true,
        "color": "black",
        color_hex: '#090d10',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/bmw_1_series_2017_1.png'),
            require('../assets/our_fleet/bmw_1_series_2017_2.png'),
            require('../assets/our_fleet/bmw_1_series_2017_3.jpg'),
            require('../assets/our_fleet/bmw_1_series_2017_4.jpg'),
            require('../assets/our_fleet/bmw_1_series_2017_5.jpg')
        ],
        "fuel_type": "Diesel",
        "parking_zone": "CA-C",
        "number_of_seats": "5",
        "price": "£50",
        "rating": "4.90",
        "parked_location": "Endell Street, London, WC2H 9BB",
        "geo_location_lat": "51.514428",
        "geo_location_lng": "-0.124237",
        "number_of_rides": "124",
        "number_of_reviews": "100",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/undefined/bmw/1-series/1993299",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": false,
        "blind_spot_sensor": false,
        "bird_eye_camera": false,
        "heated_seats": false,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "grey",
        "boot_space": "small",
        "nearby_stations": ['Covent Garden', 'Tottenham Court Road', 'Holborn']
    },
    {
        id: 4,
        "registration_number": "HY68UTC",
        "make": "BMW",
        "name": "1 Series",
        "model": "118I SE AUTO",
        "year_of_manufacture": "2018",
        "date_of_purchase": null,
        "active": true,
        "color": "silver",
        color_hex: '#bccbe0',
        "turo_account": "Shuhebur",
        "images": [
            require('../assets/our_fleet/bmw_1_series_i_se_2018_1.png'),
            require('../assets/our_fleet/bmw_1_series_i_se_2018_2.png'),
            require('../assets/our_fleet/bmw_1_series_i_se_2018_3.png'),
            require('../assets/our_fleet/bmw_1_series_i_se_2018_4.png'),
            require('../assets/our_fleet/bmw_1_series_i_se_2018_5.jpg'),
            require('../assets/our_fleet/bmw_1_series_i_se_2018_6.jpg')
        ],
        "fuel_type": "Petrol",
        "parking_zone": "CA-E",
        "number_of_seats": "5",
        "price": "£55",
        "rating": "4.98",
        "parked_location": "Gordon St, London, WC1H 0AH",
        "geo_location_lat": "51.52507",
        "geo_location_lng": "-0.132755",
        "number_of_rides": "80",
        "number_of_reviews": "66",
        "turo_link": "https://turo.com/gb/en/car-rental/united-kingdom/undefined/bmw/1-series/2210091",
        "private_hire_available": false,
        "automatic": true,
        "android_auto": false,
        "apple_carplay": false,
        "aux_input": true,
        "bluetooth": true,
        "phone_mount": false,
        "usb_charger_cable": false,
        "backup_camera": false,
        "blind_spot_sensor": false,
        "bird_eye_camera": false,
        "heated_seats": false,
        "panoramic_roof": false,
        "convertible": false,
        "congestion_free": true,
        "navigation": true,
        "interior_color": "grey",
        "boot_space": "small",
        "nearby_stations": ["Euston", "Euston Square"]
    },

]