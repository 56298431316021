import React from 'react'
import './WhyChooseUs.css'
import { SiTransportforlondon } from "react-icons/si";
import { FaLocationDot } from "react-icons/fa6";
import { TbStarFilled } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import { TbBoxMultiple9 } from "react-icons/tb";

const WhyChooseUs = () => {

    const data = [
        // {
        //     icon: <IoCarSport size={'40px'} />,
        //     title: "All Inclusive Pricing",
        //     description: "Get Everything you need in one convenient transparent price with out all inclusive-pricing"
        // },
        {
            icon: <GiReceiveMoney size={'40px'} />,
            title: "No Hidden Charges",
            description: "Enjoy peace of mind with our no hidden charges policy. We believe in easy to find, transparent and honest pricing."
        },
        {
            title: 'Explore a Diverse Fleet',
            number: '10+',
            description: 'Discover an extensive selection of premium vehicles tailored to suit every taste and occasion, ensuring you find the perfect ride for your adventure.',
            icon: <TbBoxMultiple9 size={'40px'} />
        },
        {
            title: 'Join our Satisfied Clients',
            number: '200+',
            description: 'Experience the luxury and convenience of our exceptional car rental service. Your satisfaction is our priority. Just look at our testimonials 😉',
            icon: <TbStarFilled size={'40px'} />
        },
        {
            title: 'Central London Locations',
            icon: <SiTransportforlondon size={'40px'} />,
            description: 'Conveniently located in the heart of the city, our rental hubs ensure easy access to your dream car, providing a seamless and hassle-free experience from start to finish.',
            number: <FaLocationDot size={'40px'} />
        }
    ]

    return (
        <section className='choose-us-container'>
            <div className='choose-us-content'>
                <div className='left'>
                    <img src={require('../../assets/car_images/silver_car_cut_out.png')} alt='silver car' />
                </div>
                <div className='right'>
                    <div style={{ display: 'flex', gap: 25, alignItems: 'center' }}>
                        <h3 className='small-title'>Why Choose Us</h3>
                        <img src={require('../../assets/royz-logo.png')} style={{ height: '8vw', width: '8vw' }} alt='royz-logo' />
                    </div>
                    <h1>We offer the best experience with our rental deals</h1>
                    <p className='explanation-text'>Indulge in the ultimate driving experience without the hefty price tag. Discover a curated selection of premium cars for rent in the heart of the city.</p>
                    <div className='reason-container'>
                        {data.map((item, index) =>
                            <div key={"chooseUs" + index} className='individual-reason-container'>
                                <div className='reason-icon'>{item.icon}</div>
                                <div className='reason-text-container'>
                                    <p className='reason-title'>{item.title}</p>
                                    <p className='reason-description'>{item.description}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs