import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { MdContactPhone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPhone } from "react-icons/fa6";
import "./ContactModal.css"; // Import the CSS file

const ExampleWrapper = () => {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <div className="wrapper">

            <button
                onClick={() => setIsOpen(true)}
                className="open-button"
            >
                Open Modal
            </button>
            <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export const ContactModal = ({ isOpen, setIsOpen }) => {
    const notify = () => {
        toast.success("Copied to Clipboard")

    };
    return (
        <AnimatePresence>
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="dark"
            />
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="modal-container"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="modal-content"
                    >
                        <IoIosMail className="alert-icon" />
                        <div className="modal-content-inner">
                            <div className="i-icon-middle">
                                <MdContactPhone />
                            </div>
                            <h3 className="big-text">
                                Contact Us!
                            </h3>
                            <p className="text">
                                We'll be happy to help you out and maybe even work out a better price if possible so feel free to contact us on:
                            </p>
                            <div className="contact-row">
                                <CopyToClipboard text="royzltd@outlook.com" onCopy={notify}>
                                    <div className="copy-button"><IoIosMail /></div>
                                </CopyToClipboard>
                                <CopyToClipboard text="royzltd@outlook.com" onCopy={notify}>
                                    <div className="contact-info">
                                        royzltd@outlook.com
                                    </div>
                                </CopyToClipboard>
                            </div>
                            <div className="contact-row">
                                <CopyToClipboard text="royzltd@outlook.com" onCopy={notify}>
                                    <div className="copy-button" id='phone-icon'><FaPhone /></div>
                                </CopyToClipboard>
                                <CopyToClipboard text="royzltd@outlook.com" onCopy={notify}>
                                    <div className="contact-info">
                                        07563717299
                                    </div>
                                </CopyToClipboard>
                            </div>

                            {/* <div className="button-group">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="button decline"
                                >
                                    Nah, go back
                                </button>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="button accept"
                                >
                                    Will do
                                </button>
                            </div> */}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ExampleWrapper;
