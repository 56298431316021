import React, { useContext } from 'react'
import { ContactModal } from '../components/modals/ContactModal'
import NavBar from '../components/navbar/NavBar'
import { AppContext } from '../contexts/AppContext'
import Footer from '../components/footer/Footer'
import RouteHandler from './index'
import { CarDetailsModal } from '../components/modals/carDetails/CarDetailsModal'
import { BookNowModal } from '../components/modals/BookNowModal/BookNowModal'


function ModalContainer() {
    const { showPopup, setShowPopup, showCarDetails, setShowCarDetails, showBookNow, setShowBookNow } = useContext(AppContext)

    return (
        <div className='FullScreen'>
            {/* --------- ALL MODALS ----------- */}
            <ContactModal isOpen={showPopup} setIsOpen={setShowPopup} />
            <CarDetailsModal isOpen={showCarDetails} setIsOpen={setShowCarDetails} />
            <BookNowModal isOpen={showBookNow} setIsOpen={setShowBookNow} />
            {/* -------------------------------- */}
            <NavBar />
            <RouteHandler />
            <Footer />
        </div>
    )
}

export default ModalContainer