import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import "./CarDetailsModal.css";
import 'react-toastify/dist/ReactToastify.css';
import { FaChevronRight, FaInfoCircle } from "react-icons/fa";
import { AppContext } from "../../../contexts/AppContext";
import { NavLink } from "react-router-dom";
import { IoStar } from "react-icons/io5";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { IoMdCloseCircle } from "react-icons/io";
import { FaChevronLeft } from "react-icons/fa";
import ImagesGallery from "../../imagesGallery/ImagesGallery";
import { our_fleet } from "../../../data/OurFleets";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
// import Gallery from "../../smallComponents/3DGallery/Gallery";

const ExampleWrapper = () => {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <div className="wrapper">

            <button
                onClick={() => setIsOpen(true)}
                className="open-button"
            >
                Open Modal
            </button>
            <CarDetailsModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export const CarDetailsModal = ({ isOpen, setIsOpen }) => {
    const { chosenCarIndex, setChosenCarIndex, setShowPopup, categoryFilter } = useContext(AppContext)
    const [filteredFleet, setFilteredFleet] = useState([])
    const [carDetails, setCarDetails] = useState(null)

    const car_data_length = filteredFleet.length

    useEffect(() => {
        setFilteredFleet(our_fleet?.filter((item) => categoryFilter !== 'all' ? item.make === categoryFilter : item))
        setCarDetails(filteredFleet[chosenCarIndex])
    }, [chosenCarIndex, categoryFilter])

    const handleCloseModal = () => {
        setIsOpen(false)
        setCarDetails(null)
        setChosenCarIndex(null)
    }

    const handleNav = (movingRight) => {
        if (movingRight && chosenCarIndex === car_data_length - 1) {
            setChosenCarIndex(0)
        }
        else if (!movingRight && chosenCarIndex === 0) {
            setChosenCarIndex(car_data_length - 1)
        }
        else {
            if (movingRight) setChosenCarIndex((prev) => prev + 1)
            else setChosenCarIndex((prev) => prev - 1)
        }
    }

    function SeparateEmoji({ item }) {
        // Find the index of the first whitespace
        const firstWhitespaceIndex = item.indexOf(' ');

        // Find the index of the colon after the first whitespace
        const colonIndex = item.indexOf(':', firstWhitespaceIndex);

        // Extract the emoji
        const emoji = item.slice(0, firstWhitespaceIndex);

        // Extract the part before the colon (including the colon)
        const beforeColon = item.slice(firstWhitespaceIndex, colonIndex + 1);

        // Extract the rest of the string after the colon
        const restOfString = item.slice(colonIndex + 2); // Add 2 to skip the colon and space

        return (
            <li className="emoji-li">
                <div className="emoji-bullet">{emoji}</div>
                <div className="rest-of-string">
                    <span className="before-colon">{beforeColon} </span> {restOfString}
                </div>
            </li>
        );
    }

    const handleTick = (value) => {
        if (value) return (<FaCheckCircle color="#c147f5" className="info-box-value" id="tick-values" />)
        else return (<FaXmark className="info-box-value" id="tick-values" />)
    }



    return (
        <AnimatePresence>
            {isOpen && (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={handleCloseModal}
                        className="cd-modal-container"
                    >
                        <motion.div
                            initial={{ scale: 0, rotate: "12.5deg" }}
                            animate={{ scale: 1, rotate: "0deg" }}
                            exit={{ scale: 0, rotate: "0deg" }}
                            onClick={(e) => e.stopPropagation()}
                            className="cd-modal-content"
                        >
                            <FaInfoCircle className="car-details-car-icon" />

                            {carDetails ?
                                <>
                                    <div className='car-details-left'>
                                        <ImagesGallery imagesData={carDetails?.images} />
                                        {/* <img className="car-details-image" src={carDetails?.images[0]} alt="big car" /> */}
                                        {/* <Gallery images={carDetails?.images} /> */}
                                        {carDetails?.private_hire_available &&
                                            <Link
                                                className="btn"
                                                id="alternate-button"
                                                to={'/private_rental'}
                                            >Book Privately</Link>
                                        }
                                        {
                                            carDetails?.turo_link &&
                                            <NavLink target="_blank" to={carDetails?.turo_link} className='downloadButton'>
                                                <div className={carDetails.private_hire_available ? "btn-small" : "btn-big"}>Book on Turo</div>
                                            </NavLink>
                                        }
                                        <div className="price-container">
                                            <p id='details-label'>from </p>
                                            <p id='details-price-text'>{carDetails?.price}</p>
                                            <p id='details-label'>per day</p>
                                        </div>
                                    </div>
                                    <div className='car-details-left-small'>
                                        <ImagesGallery imagesData={carDetails.images} />
                                        {carDetails.private_hire_available &&
                                            <div
                                                className="btn-big"
                                                id="alternate-button"
                                                onClick={() => setShowPopup(true)}
                                            >Book Privately</div>
                                        }
                                        {
                                            carDetails.turo_link &&
                                            <NavLink target="_blank" to={carDetails?.turo_link} className='downloadButton'>
                                                <div className={carDetails.private_hire_available ? "btn-small" : "btn-big"}>Book on Turo</div>
                                            </NavLink>
                                        }

                                        <div className="price-container">
                                            <p id='details-label'>from </p>
                                            <p id='details-price-text'>{carDetails?.price}</p>
                                            <p id='details-label'>per day</p>
                                        </div>
                                    </div>
                                    <div className='car-details-right' id='download'>
                                        <div className="car-details-headings">
                                            <h1>{carDetails?.make + " " + carDetails?.name}</h1>
                                            <h4>{`${carDetails?.model} (${carDetails?.year_of_manufacture})`}</h4>
                                        </div>
                                        <div className='card-rating-container'>
                                            <p className='card-rating-box'> <IoStar color='F5CD00' /> {carDetails?.rating} rating</p>
                                            <p id='card-rating-number-text'>{`${carDetails?.number_of_rides} rides (${carDetails?.number_of_reviews} reviews)`}</p>
                                        </div>
                                        <div className="divider-line" />
                                        <div className="car-details-scroll">
                                            <h5 className="scroll-details-heading">Details & Features ⭐</h5>
                                            <div className="info-box-container">
                                                <div className="info-box">
                                                    <div className="info-box-title">Color</div>
                                                    <div className="info-box-value">
                                                        {carDetails.color}
                                                        <div className="color-circle" style={{ backgroundColor: carDetails.color_hex }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Interior Color</div>
                                                    <div className="info-box-value">
                                                        {carDetails.interior_color}
                                                        <div className="color-circle" style={{ backgroundColor: carDetails.interior_color }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Boot Space</div>
                                                    <div className="info-box-value">{carDetails.boot_space}</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Fuel Type</div>
                                                    <div className="info-box-value">{carDetails.fuel_type}</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Number of Seats</div>
                                                    <div className="info-box-value">{carDetails.number_of_seats}</div>
                                                </div>
                                                {/* <div className="info-box">
                                                    <div>Nearby Stations</div>
                                                    <div className="info-box-value">{carDetails.nearby_stations?.map((station) =>
                                                        <div>{station}</div>
                                                    )}</div>
                                                </div> */}
                                                <div className="info-box">
                                                    <div className="info-box-title">Gearbox</div>
                                                    <div className="info-box-value">{carDetails.automatic ? 'Automatic' : 'Manual'}</div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Android Auto</div>
                                                    {handleTick(carDetails.android_auto)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Apple Carplay</div>
                                                    {handleTick(carDetails.apple_carplay)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Aux Input</div>
                                                    {handleTick(carDetails.aux_input)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Bluetooth</div>
                                                    {handleTick(carDetails.bluetooth)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Phone Mount</div>
                                                    {handleTick(carDetails.phone_mount)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">USB Charger Cable</div>
                                                    {handleTick(carDetails.usb_charger_cable)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Backup Camera</div>
                                                    {handleTick(carDetails.backup_camera)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Blind Spot Sensor</div>
                                                    {handleTick(carDetails.blind_spot_sensor)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Bird Eye Camera</div>
                                                    {handleTick(carDetails.bird_eye_camera)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Heated Seats</div>
                                                    {handleTick(carDetails.heated_seats)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Panoramic Roof</div>
                                                    {handleTick(carDetails.panoramic_roof)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Convertible</div>
                                                    {handleTick(carDetails.convertible)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Congestion Free</div>
                                                    {handleTick(carDetails.congestion_free)}
                                                </div>
                                                <div className="info-box">
                                                    <div className="info-box-title">Navigation</div>
                                                    {handleTick(carDetails.navigation)}
                                                </div>


                                            </div>


                                            {/* {carDetails?.description}

                                            <h3>Key Features ⭐</h3>
                                            <ul>
                                                {carDetails?.key_features?.map((item) =>
                                                    <SeparateEmoji item={item} />
                                                )}
                                            </ul>
                                            <h3>Other Features 😎</h3>
                                            <ul>
                                                {carDetails?.other_features?.map((item) =>
                                                    <SeparateEmoji item={item} />
                                                )}
                                            </ul> */}
                                            <h5 className="scroll-details-heading">Our Guarantees 👍🏼</h5>
                                            <ul>
                                                <SeparateEmoji item={"🧽 Cleanliness: The vehicle will be disinfected after each trip"} />
                                                <SeparateEmoji item={`📱 Responsiveness: Our team will have a 100% response rate and will get back to you as soon as possible via message while being available on call too`} />
                                                <SeparateEmoji item={`🤸🏼‍♀️ Total flexibility: accommodating and flexible with pickup or return timings. If you're running late to return, just let us know and we'll sort something out, no pressure.`} />
                                                <SeparateEmoji item={`❌ FREE Cancellation: 24 hours before the trip starts (Turo Policy)`} />
                                            </ul>
                                            <h5 className="scroll-details-heading">Drop off + Pickup Available 🚚</h5>
                                            <ul>
                                                <SeparateEmoji item={"⚪ £100: Anywhere within 7 miles of pick up"} />
                                                <SeparateEmoji item={"⚪ £150: Airports and areas further away"} />
                                                <SeparateEmoji item={"⚫ Delivery is not always guaranteed: Based on staff availability"} />
                                            </ul>
                                            {carDetails?.geo_location_lat && <APIProvider apiKey='AIzaSyCoBZZw7HyzA_WkF2x0ykyDM0IJOSYMkSU' >
                                                <div
                                                    className='car-details-map-container'
                                                >
                                                    <Map
                                                        center={{ lat: Number(carDetails?.geo_location_lat) ?? 0, lng: Number(carDetails?.geo_location_lng) ?? 0 }}
                                                        defaultCenter={{ lat: Number(carDetails?.geo_location_lat) ?? 0, lng: Number(carDetails?.geo_location_lng) ?? 0 }}
                                                        // center={{ lat: carDetails?.geo_location_lat ?? null, lng: carDetails?.geo_location_lng ?? null }}
                                                        defaultZoom={15}
                                                        mapId={"de697a2587a4d8a7"}
                                                        style={{ borderRadius: '0.75rem', width: '100%' }}
                                                    >
                                                        <AdvancedMarker zIndex={2}
                                                            position={{ lat: Number(carDetails?.geo_location_lat) ?? 0, lng: Number(carDetails?.geo_location_lng) ?? 0 }}
                                                            onClick={() => window.open(`https://maps.google.com/?q=${carDetails?.geo_location_lat},${carDetails?.geo_location_lng}`, "_blank")}
                                                            title={"Open in Google Maps"}
                                                        >
                                                            <img className='custom-icon' src={carDetails?.images[0]} alt='icon' />
                                                        </AdvancedMarker>

                                                        {/* <div className="btn-overlay">
                                                            Open in Google
                                                        </div> */}
                                                    </Map>

                                                </div >
                                            </APIProvider>}
                                        </div>

                                    </div>
                                </>
                                :
                                <div style={{ justifyContent: 'center', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 20 }}>
                                    Error Fetching Car Details

                                    <div
                                        className="btn-big"
                                        id="alternate-button"
                                        onClick={() => setShowPopup(true)}
                                    >Contact Us</div>
                                </div>
                            }

                        </motion.div>
                        <IoMdCloseCircle className="close-button" onClick={() => handleCloseModal} />
                        <div className="white-close-button-back" />

                    </motion.div>
                    <FaChevronLeft className="left-button" onClick={() => handleNav(false)} />
                    <FaChevronRight className="right-button" onClick={() => handleNav(true)} />
                </>

            )
            }

        </AnimatePresence >
    );
};

export default ExampleWrapper;
