import React from 'react'
import { IoIosQuote } from "react-icons/io";
import { BiTrip } from "react-icons/bi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import './TestimonialSlide.css'
import { NavLink } from 'react-router-dom';

export const TestimonialSlide = (props) => {
    const { name,
        image,
        rating,
        date,
        reviewText,
        trips,
        link,
        source } = props.item;

    // Function to determine the number of filled stars and outline stars based on the rating
    const renderStars = (rating) => {
        const filledStars = Math.min(5, Math.max(0, Math.floor(rating)));
        const outlineStars = Math.max(0, 5 - filledStars);

        return (
            <>
                {Array(filledStars).fill().map((_, i) => (
                    <span className='star' key={i}>
                        <AiFillStar />
                    </span>
                ))}
                {Array(outlineStars).fill().map((_, i) => (
                    <span className='star' key={filledStars + i}>
                        <AiOutlineStar />
                    </span>
                ))}
            </>
        );
    };

    return (
        // <a href={link} target="_blank" rel="noreferrer">
        <NavLink target="_blank" to={link} className='slide-container'>
            {/* <div className='slide-container'> */}
            <div className='slide-header'>
                <span className='quote'><IoIosQuote /></span>
                <div className='testimonal-rating-container'>
                    {/* <div className='testimonal-rating-text'>
                    {rating}/5
                    </div> */}
                    <div>
                        {renderStars(rating)}
                    </div>
                </div>
            </div>
            <div className='slide-body'>
                {reviewText}
            </div>
            <div className='slide-footer'>
                {image

                    ?
                    <img src={image} alt={name} />
                    :
                    <div className='no-image'>{name.charAt(0)}</div>
                }

                <div className="details">
                    <div className='footer-name'>{name}</div>
                    <p>{date?.toDateString()} {source && "| " + source} </p>

                </div>
                <div className='likesContainer'>
                    <BiTrip size={30} />
                    <p>{trips} trips</p>
                </div>
            </div>
            {/* </div> */}
        </NavLink>
        // </a>
    )
}
